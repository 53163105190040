body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  margin: 40px 50px;
}

input {
  padding: 4px 8px;
}

label {
  padding-right: 5px;
  font-weight: bold;
}

.error {
  background: #e34e3d;
  padding: 10px 15px;
  font-weight: bold;
  color: white;
}

.jokes {
  margin-top: 30px;
}

.joke {
  margin: 10px;
  padding: 8px;
}

.recent {
  font-weight: bold;
  background: #efefef;
}

.older {
  font-style: italic;
}
